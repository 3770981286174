import { Link } from "gatsby"
import React from "react"
import styles from "./why.module.css"

import transparence from "../images/icon-transparence.svg"
import efficacite from "../images/icon-efficacite.svg"
import echange from "../images/icon-echange.svg"

const Why = () => (
    <div className={styles.container}>
      <h1>Pourquoi utiliser Syndic en un clic ?</h1>
      <div className={styles.items}>
      <div className={styles.item}>
        <img width="69" height="69" src={efficacite}/>
        <h2>EFFICACITE</h2>
        <p>Déclarez un incident technique survenant dans votre immeuble et ajoutez une photo en quelques secondes. </p>
      </div>
      <div className={styles.item}>
        <img width="69" height="69" src={echange}/>
        <h2>ECHANGE</h2>
        <p>Application mobile qui vous permet d’échanger de manière conviviale avec vos voisins.</p>
      </div>
      <div className={styles.item}>
        <img width="69" height="69" src={transparence}/>
        <h2>TRANSPARENCE</h2>
        <p>Suivez en temps réel les actions menées par votre syndic, de la prise en charge de l’incident au compte rendu d’intervention.</p>
      </div>
      </div>
    </div>
)
export default Why
