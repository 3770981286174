// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Content from "../components/content"
import Features from "../components/features"
import Why from "../components/whyResident"
import Download from "../components/download"

const Residents = (props: PageProps) => (
  <Layout>
    <SEO title="Mieux communiquer avec son syndic" description="Une application pour bien vivre dans sa copropriété"/>
    <Hero>
      <h1>Une application mobile qui permet aux <b>résidents</b> d’être connectés avec leur syndic, leurs voisins et tous les acteurs de leur immeuble. </h1>
      <p>Véritable plateforme de communication, grâce à Syndic + les résidents peuvent échanger entre eux sans avoir au préalable leurs numéros de téléphone respectifs.</p>
    </Hero>
    <Content>
    <p>L’application mobile Syndic +  c’est la solution digitale pour bien vivre dans sa copropriété  : </p>
    <p>
      <ul>
        <li>Déclarer un incident 24h/24</li>
        <li>Suivre en temps réel la résolution de l’incident technique</li>
        <li>Être informé de l’intervention du prestataire technique</li>
        <li>Échanger facilement avec son gestionnaire syndic</li>
        <li>Être connecté aux résidents et acteurs de votre immeuble</li>
      </ul>
    </p>
    </Content>
    <Why/>
    <Features/>
    <Download/>
  </Layout>
)

export default Residents
